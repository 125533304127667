import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink, Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import secureStorage from './../../config/encrypt';
import MasterService from './../../service/MasterService';
class Navbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            langOption : []
        }
    }
    componentWillMount = ()=>{
        let localLangData = secureStorage.getItem('langOption');
        if(!localLangData) {
            MasterService.get('core/commonservice/master?master[]=lang').then((response)=>{
                if (response.status == 200 && response.data.status == 200) {
                    let langData = response.data.data.lang;
                    secureStorage.setItem('langOption', langData);
                    this.setState({langOption: langData});
                }
            });
        }else{
            this.setState({langOption: localLangData});
        }
    }

    handleLogout = () => {
        if (typeof this.props.logoutUser === 'function') {
            this.props.logoutUser();
        }
    }
    render() {
        let name = "Admin";
        let role, profileTitle, userType, dealerName,stock_category=[];
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
                name = loginResponse.data.user_data.name;
                role = loginResponse.data.user_data.role;
                userType = loginResponse.data.user_data.role_type;
            }
            if (loginResponse && loginResponse.data && loginResponse.data.dealer_data && loginResponse.data.dealer_data[0]) {
                let dealer = loginResponse.data.dealer_data[0];
                if(Array.isArray(dealer.stock_category))
                    stock_category =  dealer.stock_category;
                dealerName = `${dealer.organization} (${dealer.gcd_code})`;
            }
            profileTitle = [dealerName, userType].filter(v => v).join(" | ")
        }

        const { t, i18n } = this.props;
        const selLang = secureStorage.getItem('lang') || 'en';
        const {langOption} = this.state;

        return (
            <nav className={this.props.navClass}>
                <ul className="navbar-nav">
                    <li><NavLink to="/" exact>{this.props.t('menu.home')}</NavLink></li>
                    {role == 'admin' &&
                        <li className="dropdown"><NavLink to="/">{this.props.t('menu.dashboard')}<span className="caret"></span></NavLink>
                            <ul className="dropdown-menu">
                                <li>
                                    <Link to="/admin/dealer-list">{this.props.t('menu.dealer_manager')}</Link>
                                </li>
                                <li>
                                    <Link to="/admin/super-admin-report">{this.props.t('menu.super_admin_report')}</Link>
                                </li>
                                <li>
                                    <Link to="/admin/reset-account-block">{this.props.t('menu.reset_account_block')}</Link>
                                </li>
                                <li>
                                    <Link to="/admin/ac-manager">{this.props.t('menu.account_manager')}</Link>
                                </li>
                            </ul>
                        </li>}
                    {role == 'dealercentral' && <>
                        <li className="dropdown">
                            <NavLink to={{ pathname: '/manage-stock', state: 'freshLoadStock' }}
                                isActive={(match, location) => {
                                    if (location.pathname == '/add-stock' || location.pathname == '/manage-stock') { return true; }
                                }}>
                                {this.props.t('menu.stock_manager')}<span className="caret"></span></NavLink>
                            <ul className="dropdown-menu">
                                {(stock_category.includes(1)) ?
                                    <><li><NavLink to={{ pathname: '/manage-stock', state: 'freshLoadStock' }}>{this.props.t('menu.manage_stock')}</NavLink></li>
                                        <li><NavLink to="/add-stock">{this.props.t('menu.add_stock')}</NavLink></li></>
                                    : ""}
                                {(stock_category.includes(3)) ?
                                    <><li><NavLink to={{ pathname: '/manage-truck', state: 'freshLoadStock' }}>{this.props.t('menu.manage_truck')}</NavLink></li>
                                        <li><NavLink to="/add-truck">{this.props.t('menu.add_truck')}</NavLink></li></>
                                    : ""}
                            </ul>
                        </li>
                        <li className="dropdown">
                            <NavLink to="/lead"
                                isActive={(match, location) => {
                                    if (location.pathname == '/add-lead' || location.pathname == '/lead') { return true; }
                                }}>{this.props.t('menu.buyer_enquiry')}<span className="caret"></span></NavLink>
                                <ul className="dropdown-menu">
                                    <li><Link to={{ pathname: '/lead', state: 'loadAllLead' }}>{this.props.t('menu.manage_enquiry')}</Link></li>
                                    <li><Link to="/add-lead">{this.props.t('menu.add_enquiry')}</Link></li>
                                </ul>
                        </li>
                    </>}
                    <li className="dropdown myac" title={profileTitle || ''}><a href="">
                        <span className="userac"><i className="ic-person"></i></span>
                        {this.props.t('menu.hi')} {name}<span className="caret"></span></a>
                        <ul className="dropdown-menu">
                            {role == 'dealercentral' && <li><NavLink to="/my-account/basic-details">{this.props.t('menu.my_account')}</NavLink></li>}
                            <li onClick={this.handleLogout}><NavLink to="">{this.props.t('menu.logout')}</NavLink></li>
                        </ul>
                    </li>
                    {langOption.length > 1 ?
                        <li>
                            <div className="language-change">
                                {langOption.map((lang, index) =>
                                    <button key={index} type='button' className={selLang==lang.iso_code?'btn btn-primary':'btn btn-default'} onClick={() => {
                                        secureStorage.setItem('lang', lang.iso_code);
                                        i18n.changeLanguage(lang.iso_code)
                                    }}>{lang.iso_code}</button>
                                )}
                            </div>
                        </li>
                    : ''}
                </ul>
            </nav>
        )
    }
}

export default withTranslation('common')(Navbar);