import React, { Component } from 'react';
import Previews from './upload';
import Select from 'react-select';
//import Fieldset from "../../elements/Fieldset"
import InputField from '../../elements/InputField';
import DatePicker from "react-datepicker";
// import CheckBox from '../../elements/CheckBox';
// import Radiobox from '../../elements/Radiobox';
import { connect } from 'react-redux';

import secureStorage from './../../../config/encrypt';
import { Redirect } from 'react-router-dom';
import MmvDropDown from '../../enquiry/component/MmvDropDown';
import { withTranslation } from 'react-i18next';
import { InventoryService } from './../../../service';
import { OWNER, MONTH, DESCRIPTION_FIELD_MIN_LENGTH, CONF_VAL, TOTAL_MGF_YEAR, MIN_STOCK_IMAGE_LIMIT, DISABLE_REGISTRATION_NUMBER_TYPE } from './../../../config/constant';
import dateFormat from 'dateformat';

import carImg from './../assets/images/main-car-photo.png';
import truckImg from './../assets/images/main-truck-photo.png';
import shapeImg from './../assets/images/ishape.png';
import { toast } from 'react-toastify';
import ValidationFiles from './../../../validation/inventory';
import { async } from 'q';
import { Editor } from '@tinymce/tinymce-react';
const CURRENCY_SEPARATOR = CONF_VAL.CURRENCY_SEPARATOR || ".";
let country_code=CONF_VAL.COUNTRY_CODE;
let INVENTORY_REG_DETAIL = CONF_VAL.INVENTORY_REG_DETAIL;
const AddStockValidation = ValidationFiles[country_code];
let MAKE_MONTH=CONF_VAL.MAKE_MONTH;
let TRANSLATION_REG_YEAR_LABEL=CONF_VAL.TRANSLATION_REG_YEAR_LABEL;
let REG_MONTH=CONF_VAL.REG_MONTH;

let curr_year = parseInt(dateFormat('yyyy'), 10);
const year = [];
const validreg_year = [];
const tax_expiry_year = []
let stock_category = 1;
let redirectUrl = 'manage-stock';
let stockImage = carImg;
for (let i = curr_year; i >= (TOTAL_MGF_YEAR); i--) {
    year.push({
        value: i,
        label: i
    });
}

for (let i = (curr_year + 5); i >= (curr_year - 2); i--) {
    validreg_year.push({
        value: i,
        label: i
    });
}

for (let i = (curr_year + 2); i >= (curr_year); i--) {
    tax_expiry_year.push({
        value: i,
        label: i
    });
}

class AddStock extends Component {
    constructor(props) {
        super(props);        
        this.handleSelect = this.handleSelect.bind(this);
        this.handleChangetext = this.handleChangetext.bind(this);
        this.handelFilesUpload = this.handelFilesUpload.bind(this);
        this.editorInstanse = {};
        const currentRoute = this.props.location.pathname
        let stockInfo = 'Car';
        if (currentRoute == '/add-truck' || currentRoute.includes('add-truck')) {
            stock_category = 3;
            redirectUrl = 'manage-truck';
            stockImage = truckImg;
            stockInfo = 'Truck';
        }
        else if (currentRoute == '/add-stock' || currentRoute.includes('add-stock')) {
            stock_category = 1;
            redirectUrl = 'manage-stock';
            stockImage = carImg;
            stockInfo = 'Car';
        }
        this.state = {
            loading: false,
            redirect: false,
            savemode: 'add',
            files: [{}],
            cityAllList: [],
            colorList: [],
            tenureList:[],
            stockType:stockInfo,
            carRegisterEnableStatus: false,
            errors: {},
            formData: {
                is_registered_car: 1,
                classifiedStock: 0
            },
            filesData: [],
            redirectToList: false,
            versionDetailJson:{},
            hideshow:"row hide"  ,
            autoClassifiedFlag:false,
            tenureToselect:{}
        };
    }

    componentDidMount = async () => {
        await this.updateReduxData();
        this.getCityList();
        this.getColorList();
        this.getEmiTenureList();
        this.getStockcategoryList();
        let used_car_stock_id = this.props.match.params.used_car_stock_id;
        if (used_car_stock_id) {
            this.setState({
                savemode: 'update'
            });
            this.getCarDetail();
        }
        else {
            this.checkAutoClassifiedStatus({savemode:'add'});
        }
    }

    getStockcategoryList() {
        InventoryService.getMasters(["stock_category"]).then((response) => {
            if (response && response.status == 200 && response.data.status == 200) {
                let stock = {};
                response.data.data.stock_category.forEach(e => {
                    stock[e.key] = e;
                });
                this.setState({ stockType: stock[stock_category].value});
            }
        }).catch((error) => {
            this.setState({ loading: false })
        });
    }

    updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
        } else {
            this.setState({ redirect: true })
        }
    }

    getCarDetail = async () => {
        let used_car_stock_id = this.props.match.params.used_car_stock_id;
        let getUpdatePostdata = {
            car_hash: used_car_stock_id
        };
        let thisObj = this;
        InventoryService.getCarDetail(getUpdatePostdata)
            .then((response) => {
                if (response.data.status == 200) {
                    let responseCardetail = response.data.data;
                    if (responseCardetail.car_status != '1' || responseCardetail.certification_status != '0' || stock_category != responseCardetail.category_id) {
                        thisObj.setState({ redirectToList: true });
                    }
                    //formData['reg_no']=responseCardetail.reg_no;
                    thisObj.setState({
                        redirectToList: false,
                        carRegisterEnableStatus: (responseCardetail.reg_no) ? false : true,
                        formData: {
                            ...thisObj.state.formData,
                            stock_used_car_id: responseCardetail.id,
                            version_id: responseCardetail.version_id,
                            reg_place_city_id: responseCardetail.reg_place_city_id,
                            uc_colour_id: responseCardetail.uc_colour_id,
                            owner_type: responseCardetail.owner_type,
                            make_month: responseCardetail.make_month,
                            make_year: responseCardetail.make_year,
                            reg_no: responseCardetail.reg_no,
                            reg_month: responseCardetail.reg_month,
                            reg_valid_month: responseCardetail.reg_valid_month,
                            reg_valid_year: responseCardetail.reg_valid_year,
                            is_reg_no_show: (parseInt(responseCardetail.is_reg_no_show)) ? 1 : 0,
                            km_driven: responseCardetail.km_driven,
                            city_covered: responseCardetail.city_covered,
                            insurance_type: responseCardetail.insurance,
                            insurance_exp_year: responseCardetail.insurance_exp_year,
                            insurance_exp_month: responseCardetail.insurance_exp_month,
                            car_price: responseCardetail.car_price,
                            reg_year: responseCardetail.reg_year,
                            ip_address: responseCardetail.ip_address,
                            description: responseCardetail.description,
                            is_registered_car: (responseCardetail.reg_no) ? 1 : 0,
                            make_id: responseCardetail.make_id,
                            model_id: responseCardetail.model_id,
                            isclassified: responseCardetail.isclassified,
                            category_id: responseCardetail.category_id,
                            usedCarImage: (responseCardetail.usedCarImage) ? responseCardetail.usedCarImage : [],
                            //reg_valid_date: (responseCardetail.reg_valid_date == '0000-00-00')?'':responseCardetail.reg_valid_date,
                            other_colour: (responseCardetail.uc_colour_id == 0) ? responseCardetail.uc_colour : '',
                            display_price: this.__numberWithCommas(responseCardetail.car_price),
                            display_km: this.__numberWithCommas(responseCardetail.km_driven),
                            tax_expiry_month: responseCardetail.tax_expiry_month,
                            tax_expiry_year: responseCardetail.tax_expiry_year,
                            downpayment: responseCardetail.down_payment,
                            display_downpayment: this.__numberWithCommas(responseCardetail.down_payment),
                            number_type: responseCardetail.number_type ,
                            emi: responseCardetail.emi_amount,
                            display_emi: this.__numberWithCommas(responseCardetail.emi_amount),
                            emi_count: responseCardetail.total_emi
                        }
                    });
                }
                else {
                    thisObj.setState({ redirectToList: true });
                }
            }).catch((error) => {
            })
    }

    __numberWithCommas = (num) => {
        num = num == null ? "" : "" + num;
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1"+CURRENCY_SEPARATOR)
    }

    __numToWords = n => {
        if(!n) return '';
        var string = n.toString(), units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words, and = 'and';
        /* Remove spaces and commas */
        string = string.replace(/[, ]/g, "");
        /* Is number zero? */
        if (parseInt(string) === 0) {
            return 'zero';
        }
        /* Array of units as words */
        units = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
        /* Array of tens as words */
        tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
        /* Array of scales as words */
        scales = ['', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion'];
        /* Split user arguemnt into 3 digit chunks from right to left */
        start = string.length;
        chunks = [];
        while (start > 0) {
            end = start;
            chunks.push(string.slice((start = Math.max(0, start - 3)), end));
        }

        /* Check if function has enough scale words to be able to stringify the user argument */
        chunksLen = chunks.length;
        if (chunksLen > scales.length) {
            return '';
        }

        /* Stringify each integer in each chunk */
        words = [];
        for (i = 0; i < chunksLen; i++) {

            chunk = parseInt(chunks[i]);

            if (chunk) {

                /* Split chunk into array of individual integers */
                ints = chunks[i].split('').reverse().map(parseFloat);

                /* If tens integer is 1, i.e. 10, then add 10 to units integer */
                if (ints[1] === 1) {
                    ints[0] += 10;
                }

                /* Add scale word if chunk is not zero and array item exists */
                if ((word = scales[i])) {
                    words.push(word);
                }

                /* Add unit word if array item exists */
                if ((word = units[ints[0]])) {
                    words.push(word);
                }

                /* Add tens word if array item exists */
                if ((word = tens[ints[1]])) {
                    words.push(word);
                }

                /* Add 'and' string after units or tens integer if: */
                if (ints[0] || ints[1]) {

                    /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
                    if( ints[2] || (i + 1) < chunksLen ) { words.push( and ); and = ''; }

                }

                /* Add hundreds word if array item exists */
                if ((word = units[ints[2]])) {
                    words.push(word + ' hundred');
                }

            }

        }

        return words.reverse().join(' ');
    }

    handelFilesUpload(files) {
        this.setState({ filesData: files });
    }

    handleSelect(key) {
        this.setState({ key });
    }

    handleChange = (sname, ovalue) => {
        let formData = { ...this.state.formData };
        formData[sname] = ovalue.value;
        this.setState({
            formData:formData
        });
    }
    handleChangeById = (sname, ovalue) => {
        //console.log('sname',ovalue.id)
        let formData = { ...this.state.formData };
        formData[sname] = ovalue.id;
        this.setState({
            formData:formData
        });
    }
    handledate = (datefield, date) => {
        let formData = { ...this.state.formData };
        formData[datefield] = new Date(date);
        this.setState({
            formData:formData
        });
    }
    handleChangetext = (event) => {
        let formData = { ...this.state.formData };        

        if (event.target.name == 'car_price' || event.target.name == 'km_driven' || event.target.name == 'downpayment' || event.target.name == 'emi') {
            var replace = "["+CURRENCY_SEPARATOR+"]";
            var re = new RegExp(replace,"g");
            event.target.value = event.target.value.replace(re, '');
            var er = /^-?[0-9]+$/;            
            if (er.test(event.target.value)) {                
                formData[event.target.name] = event.target.value;
                if (event.target.name == 'car_price') {
                    formData['display_price'] = this.__numberWithCommas(event.target.value);
                }
                else if (event.target.name == 'km_driven') {
                    formData['display_km'] = this.__numberWithCommas(event.target.value);;
                }else if (event.target.name == 'downpayment') {
                    formData['display_downpayment'] = this.__numberWithCommas(event.target.value);
                }else if (event.target.name == 'emi') {
                    formData['display_emi'] = this.__numberWithCommas(event.target.value);
                }

            }
            else if(event.target.value == ''){     
                formData[event.target.name] = event.target.value;                          
                if (event.target.name == 'car_price') {
                    formData['display_price'] = '';
                }
                else if (event.target.name == 'km_driven') {
                    formData['display_km'] = '';
                } else if (event.target.name == 'downpayment') {
                    formData['display_downpayment'] = '';
                }else if (event.target.name == 'emi') {
                    formData['display_emi'] = '';
                }
            }                                   
        }
        else if(event.target.name == 'reg_no'){
            formData[event.target.name] = event.target.value.replace(/[^0-9a-z]/gi, '');
        }
        else{            
            formData[event.target.name] = event.target.value;
        }
        this.setState({
            formData: formData
        });
    }
    handleVersionChange = async(sname, ovalue) => {
        let formData = { ...this.state.formData };
        let option_value = (sname === 'car_version') ? ovalue.vn_id : ovalue.id;
                
        if(sname === 'car_version' && option_value){
            formData['version_id'] = option_value;
            let response=await InventoryService.getVersionDetail({version_id:option_value});
            if (response.data.status == 200) {
                let responseVersiondetail = response.data.data;
                if(responseVersiondetail.length){
                    this.setState({ versionDetailJson: responseVersiondetail[0],hideshow:"row" });
                }     
            }
        }else{
            formData['version_id'] =  0;
            this.setState({ hideshow:"row hide" });
        }
        
        this.setState({
            formData:formData
        });

    }
    submitAddStockForm = (event) => {
        event.preventDefault();
        let validFormDataResponse = AddStockValidation.validFormData(this.state.formData, this.props, { images: this.state.filesData });
        let returnError=validFormDataResponse.errors;
        this.setState({ errors: returnError })
        if (validFormDataResponse.formIsValid) {
            let formData = { ...this.state.formData };
            if (!formData.hasOwnProperty('is_reg_no_show')) {
                formData.is_reg_no_show = 0;
            }
            formData.dealer_city_id = this.props.dealer_city_id;
            formData.category_id = stock_category;
            this.setState({ loading: true });
            let thisObj = this;
            if (this.state.filesData.length < MIN_STOCK_IMAGE_LIMIT && formData.classifiedStock == 1 && formData.category_id == 1) {
                this.setState({ loading: false });
                toast.error(this.props.t('addInventory.min_image_upload_msg'))
            } else {
                InventoryService.addStock(formData)
                    .then((response) => {
                        this.setState({ loading: false });
                        if (response.data.status == 200) {
                            if (response.data.data) {
                                this.setState({
                                    formData: {
                                        ...this.state.formData,
                                        stock_used_car_id: response.data.data.id
                                    }
                                }, () => {
                                    thisObj.s3UploadFiles();
                                })
                            }
                        } else {
                            toast.error(response.data.message);
                        }
                    }).catch((error) => {
                        toast.error(error);
                        this.setState({ loading: false });
                    })
            }
        }
    }
    
    submitUpdateStockForm = (event) => {
        event.preventDefault();
        let validFormDataResponse = AddStockValidation.validFormData(this.state.formData, this.props, { images: this.state.filesData });
        let returnError=validFormDataResponse.errors;
        this.setState({ errors: returnError })
        if (validFormDataResponse.formIsValid) {
            let formData = { ...this.state.formData };
            formData.dealer_city_id = this.props.dealer_city_id;
            this.setState({ loading: true });
            let thisObj = this;
            if (this.state.filesData.length < MIN_STOCK_IMAGE_LIMIT && formData.isclassified == '1' && formData.category_id == 1) {
                this.setState({ loading: false });
                toast.error(this.props.t('addInventory.min_image_upload_msg'))
            } else {
                InventoryService.updateStock(formData)
                    .then((response) => {
                        this.setState({ loading: false });
                        if (response.data.status == 200) {
                            if (response.data.data) {
                                thisObj.s3UploadFiles();
                            }
                        } else {
                            toast.error(response.data.message);
                        }
                    }).catch((error) => {
                        this.setState({ loading: false });
                    })
            }
        }
    }

    s3UploadFiles = () => {
        if (this.state.filesData.length) {
            var formData = new FormData();
            let preUploadedImages = [];
            let newFileFlag = false;
            formData.append("upload_type", 'inventory_car_images');
            this.state.filesData.map((file,idx) => {
                if (file.filename) {
                    preUploadedImages.push({
                        file_name: file.filename,
                        file_url: file.preview,
                        img_position: idx
                    });
                }
                else {
                    formData.append('images', file);
                    newFileFlag = true;
                }
            });

            if (newFileFlag) {
                let thisObj = this;
                toast.info('uploading files...');
                InventoryService.uploadFilesToS3(formData, { 'Content-Type': 'multipart/form-data' })
                    .then((response) => {
                        if (response.data.status == 200) {
                            if (response.data.data.length) {
                                let carImages = [];
                                if(preUploadedImages.length){
                                    let cnt = 0;
                                    preUploadedImages.forEach( preuploadImg => {                                        
                                        while (preuploadImg.img_position != cnt) {
                                            carImages.push(response.data.data.shift());
                                            cnt++;
                                        }
                                        if(preuploadImg.img_position == cnt){
                                            carImages.push(preuploadImg);                                                
                                        }                                        
                                        cnt++;
                                    });                                                                        
                                    if(response.data.data.length){
                                        response.data.data.forEach(resImg => carImages.push(resImg));                                        
                                    }                                    
                                }
                                else{
                                    carImages = [...response.data.data];
                                }                                                                       
                                thisObj.saveImages(carImages);
                            }
                        }
                    })
                    .catch((error) => {

                    });
            }
            else {
                this.saveImages(preUploadedImages);
            }

        }
        else {
            this.saveImages([], true);
        }
    }

    saveImages = (data, remove_all) => {
        let msg = "";
        let usedcar_id = this.state.formData.stock_used_car_id;
        if (data.length) {
            let image_file_names = [];
            let image_file_urls = [];

            data.forEach((img) => {
                image_file_names.push(img.file_name);
                image_file_urls.push(img.file_url);
            });
            InventoryService.saveUsedCarImages({ image_file_names: image_file_names, image_file_urls: image_file_urls, usedcar_id: usedcar_id })
                .then((response) => {
                    //console.log(response.data);
                    msg = response.data.message
                    this.setState({ loading: false, redirectToList: true });
                    toast.success(msg);
                }).catch((error) => {                    
                    console.error(error)
                    this.setState({ loading: false });
                });
        }
        else if (remove_all && usedcar_id > 0) {
            InventoryService.deleteAllUsedCarImages({ usedcar_id: usedcar_id })
                .then((response) => {
                    //console.log(response.data);
                    msg = response.data.message
                    toast.success(msg);
                    this.setState({ loading: false, redirectToList: true });
                //     return this.autoClassifedOnAdd(usedcar_id, this.state.savemode);
                // }).then(classifiedRes => {
                //     toast.success(msg);
                //     this.setState({ loading: false, redirectToList: true });
                }).catch((error) => {
                    this.setState({ loading: false });
                });
        }
        else {
            toast.success(this.props.t('addInventory.success'));
            this.setState({ loading: false, redirectToList: true });
            // this.autoClassifedOnAdd(usedcar_id, this.state.savemode).then(classifiedRes => {
            //     toast.success(this.props.t('addInventory.success'));
            //     this.setState({ loading: false, redirectToList: true });
            // });
        }
    }
    autoClassifedOnAdd = (used_car_id, saveMode) => {
        return new Promise((resolve, reject) => {
            if(saveMode != "add") return resolve();
            return InventoryService.makeClassifedStockCond({ used_car_id }).then(r2 => resolve()).catch(er => resolve(er));
        })
    }

    setInsuranceType = (event) => {
        let formData = { ...this.state.formData };
        formData[event.target.name] = event.target.value;
        this.setState({
            formData
        });
    }
    setRegistrationStatus = (event) => {
        let formData = { ...this.state.formData };
        let insuranceType = 0;
        if (event.target.checked) {
            insuranceType = 1;
            this.setState({
                carRegisterEnableStatus: false
            });
        } else {
            this.setState({
                carRegisterEnableStatus: true
            });
        }
        formData[event.target.name] = insuranceType;
        this.setState({
            formData
        });
    }

    setClassifiedStockStatus = (event) => {
        let formData = { ...this.state.formData };
        let classifiedStock = 0;
        if (event.target.checked) {
            classifiedStock = 1;
            this.setState({
                autoClassifiedFlag: true
            });
        } else {
            this.setState({
                autoClassifiedFlag: false
            });
        }
        formData[event.target.name] = classifiedStock;
        this.setState({
            formData
        });
    }

    setRegistrationShow = (event) => {
        let formData = { ...this.state.formData };
        let checkedVal = 0;
        if (event.target.checked) {
            checkedVal = 1;
        }

        formData[event.target.name] = checkedVal;
        this.setState({
            formData
        });
    }

    setNumberType = (event) => {
        let formData = { ...this.state.formData };
        formData[event.target.name] = event.target.value;
        this.setState({
            formData
        });
    }

    getCityList() {
        var thisObj = this;
        InventoryService.cityList()
            .then(function (response) {
                if (response.data.status == 200) {
                    thisObj.setState({
                        cityAllList: response.data.data.city
                    })
                }
            })
            .catch(function (response) {
            });
    }
    getColorList() {
        var thisObj = this;
        InventoryService.colorList()
            .then(function (response) {
                if (response.data.status == 200) {
                    thisObj.setState({
                        colorList: response.data.data
                    })
                }
            })
            .catch(function (response) {
            });
    }
    checkAutoClassifiedStatus(params) {
        var thisObj = this;
        InventoryService.checkAutoClassified(params)
            .then(function (response) {
                let classifiedStock = response.data.data && response.data.data.can_classified == true;
                if (classifiedStock) {
                    let formData = { ...thisObj.state.formData };
                    formData['classifiedStock'] = 1;
                    thisObj.setState({
                        autoClassifiedFlag: true,
                        formData
                    })
                } else {
                    thisObj.setState({
                        autoClassifiedFlag: false
                    })
                }
            })
            .catch(function (response) {
            });
    }

    getEmiTenureList() {
        InventoryService.getMasters(["emi_tenure_list"]).then((response) => {
            if (response && response.status == 200 && response.data.status == 200) {
                this.setState({ tenureList: response.data.data.emi_tenure_list });
            }
        }).catch((error) => {
            this.setState({ loading: false })
        });
    }


    validFormData = () => {
        let formData = this.state.formData;
        let errors = {};
        let formIsValid = true;
        var numbers = /^[0-9]+$/;
        if (!formData['make_month']) {
            formIsValid = false;
            errors["make_month"] = this.props.t('addInventory.validation_error.make_month_is_required');
        }
        let descriptionCount = this.editorInstanse.plugins.wordcount.body.getCharacterCount();        
        if(formData['description'] != null && descriptionCount < DESCRIPTION_FIELD_MIN_LENGTH && descriptionCount != 0) {
            formIsValid = false;
            errors["description"] = this.props.t('addInventory.validation_error.description_char_limit');
        }
        if (!formData['make_year']) {
            formIsValid = false;
            errors["make_year"] = this.props.t('addInventory.validation_error.make_year_is_required');
        }
        if (!formData['version_id']) {
            formIsValid = false;
            errors["version_id"] = this.props.t('addInventory.validation_error.Car_version_is_required',{stock_type:this.state.stockType});
        }
        if (formData['is_registered_car'] && formData['is_registered_car'] == "1") {
            if (!formData['reg_no']) {
                formIsValid = false;
                errors["reg_no"] = this.props.t('addInventory.validation_error.Registration_number_is_required');
            }
            
            if (formData['reg_no'] && (formData['reg_no'].length < 3 || formData['reg_no'].length > 9)) {
                formIsValid = false;
                errors["reg_no"] = this.props.t('addInventory.validation_error.registration_number_length_validation');
            }

            // if (!formData['reg_month']) {
            //     formIsValid = false;
            //     errors["reg_month"] = this.props.t('addInventory.validation_error.Registration_month_is_required');
            // }
            // if (!formData['reg_year']) {
            //     formIsValid = false;
            //     errors["reg_year"] = this.props.t('addInventory.validation_error.Registration_year_is_required');
            // }
            if (formData['reg_month'] && !formData['reg_year']) {
                formIsValid = false;
                errors["reg_year"] = this.props.t('addInventory.validation_error.Registration_year_is_required');
            }
            if (formData['reg_year'] && !formData['reg_month']) {
                formIsValid = false;
                errors["reg_month"] = this.props.t('addInventory.validation_error.Registration_month_is_required');
            }
            
            if(formData['reg_month'] && formData['reg_year'] && formData['make_month'] && formData['make_year']){
                if(formData['reg_year'] == formData['make_year'] && formData['reg_month'] < formData['make_month']){
                    formIsValid = false;
                    errors["reg_month"] = this.props.t('addInventory.validation_error.reg_month_greater');                    
                }
                else if(formData['make_year'] > formData['reg_year']){
                    formIsValid = false;
                    errors["reg_year"] = this.props.t('addInventory.validation_error.reg_year_smaller')                    
                }
            }

            // if (!formData['reg_valid_date']) {
            //     formIsValid = false;
            //     errors["reg_valid_date"] = this.props.t('addInventory.validation_error.no_plate_valid_req');
            // }

            // if (!formData['reg_valid_month']) {
            //     formIsValid = false;
            //     errors["reg_valid_month"] = "Number plate valid month is required.";
            // }
            // if (!formData['reg_valid_year']) {
            //     formIsValid = false;
            //     errors["reg_valid_year"] = "Number plate valid year is required.";
            // }

            let reg_make_month = formData['reg_month'] || formData['make_month'];
            let reg_make_year =  (formData['reg_month'])? formData['reg_year'] : formData['make_year'];

            if(reg_make_month && reg_make_year && formData['reg_valid_month'] && formData['reg_valid_year']){
                if(reg_make_year == formData['reg_valid_year'] && reg_make_month > formData['reg_valid_month']){
                    formIsValid = false;
                    errors["reg_valid_month"] = this.props.t('addInventory.validation_error.no_plate_valid_month');
                }
                else if(reg_make_year > formData['reg_valid_year']){
                    formIsValid = false;
                    errors["reg_valid_year"] = this.props.t('addInventory.validation_error.no_plate_valid_year');
                }
            }

            if (!formData['reg_place_city_id']) {
                formIsValid = false;
                errors["reg_place_city_id"] = this.props.t('addInventory.validation_error.Registration_city_is_required');
            }
        }

        if (!formData['km_driven']) {
            formIsValid = false;
            errors["km_driven"] = this.props.t('addInventory.validation_error.Car_driven_km_is_required',{stock_type:this.state.stockType});
        } else if ((formData['km_driven'].toString()).match(numbers) == null) {
            formIsValid = false;
            errors["km_driven"] = this.props.t('addInventory.validation_error.Car_driven_should_numeric_characters_only',{stock_type:this.state.stockType});
        }
        else if (formData['km_driven'] < 1000 || formData['km_driven'] > 1000000) {
            formIsValid = false;
            errors["km_driven"] = this.props.t('addInventory.validation_error.Car_driven_should_less_than',{stock_type:this.state.stockType});
        }
        if (!formData['uc_colour_id'] && !formData['other_colour']) {
            formIsValid = false;
            errors["uc_colour_id"] = this.props.t('addInventory.validation_error.Car_colour_is_required',{stock_type:this.state.stockType});
        }
        if (!formData['owner_type']) {
            formIsValid = false;
            errors["owner_type"] = this.props.t('addInventory.validation_error.Owner_type_is_required');
        }
        if (!formData['insurance_type']) {
            formIsValid = false;
            errors["insurance_type"] = this.props.t('addInventory.validation_error.Insurance_type_is_required');
        }
        if (!formData['car_price']) {
            formIsValid = false;
            errors["car_price"] = this.props.t('addInventory.validation_error.Car_price_is_required',{stock_type:this.state.stockType});
        } else if (formData['car_price'].toString().match(numbers) == null) {
            formIsValid = false;
            errors["car_price"] = this.props.t('addInventory.validation_error.Car_price_should_numeric_characters_only',{stock_type:this.state.stockType});
        }


        // if (formData['reg_valid_date'] && formData['reg_month'] && formData['reg_year']) {
        //     let reg_valid_date_obj = new Date(formData['reg_valid_date']);

        //     //let reg_valid_month_year = new Date(reg_valid_date_obj.getFullYear()) + '-' + (reg_valid_date_obj.getMonth() + 1);
        //     let reg_monthyear = new Date(formData['reg_year'] + '-' + formData['reg_month'])
        //     //console.log('reg_monthyear', reg_valid_date_obj.getTime(), reg_monthyear.getTime())

        //     if (reg_valid_date_obj.getTime() < reg_monthyear.getTime()) {
        //         errors["reg_valid_date"] = this.props.t('addInventory.validation_error.Should_be_greater_than_Registration_Date');
        //     }

        // }

        if (!formIsValid) {
            toast.error(this.props.t('addInventory.validation_error.fill_required_fields'));
        }

        this.setState({ errors: errors })
        return formIsValid;
    }

    handleEditorChange = (content, editor) => {                  
        this.setState({
                    formData: {
                            ...this.state.formData,
                            description: content
                        }                        
                    });
    }

    setDescriptionEditorInstanse = (editorInstanse) => {                      
        if(Object.keys(this.editorInstanse).length === 0 ){            
            this.editorInstanse = editorInstanse;
        }        
    }

    render() {
        let yearDisable = false , makeDisable = false , modelDisable = false , monthDisable = false;
        if (this.state.savemode == 'update'){
            makeDisable = true;
            modelDisable = true; 
            yearDisable = true;
            monthDisable = true;
        }
        if (this.state.redirect) {
            return (<Redirect to={'/login'} />);
        }

        if (this.state.redirectToList) {
            return (<Redirect to={`/${redirectUrl}`} />);
        }

        const { formData, cityAllList, colorList,tenureList, savemode,stockType, errors, carRegisterEnableStatus,versionDetailJson} = this.state;
        //console.log('versionDetailJson-------------->>>>>>>>>>>>>',versionDetailJson.fuel_type);
        let css = '';
        if (errors) {
            css = `.form-error {display:block}`;
        }
        //console.log(formData);
        let lang_code = secureStorage.getItem('lang');
        if (!lang_code) {
            lang_code = 'en';
        }
        let classifiedStock = formData.classifiedStock;
        return (
            <div className="container-fluid addStock_DC">
                <style type="text/css">
                    {css}
                </style>
                <div className="addStock">
                    {
                        savemode == 'add' ?
                            <h1>{this.props.t('addInventory.add_stock', { stock_type: stockType })}</h1>
                            : <h1>{this.props.t('addInventory.edit_stock', { stock_type: stockType })}</h1>
                    }
                    <form onSubmit={this.handleSubmitAddStock} className={this.state.loading ? "addStock_form formHeight loading" : "addStock_form formHeight"}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-heading">
                                        <h2 className="card-title">{this.props.t('addInventory.upload_photos')}</h2>
                                    </div>
                                    <div className="card-body">
                                        {/* <Fieldset legend="Upload Photos" /> */}
                                        <div className="row form-group">
                                            <div className="col-sm-2 text-right">
                                                <img src={stockImage} className="main-img" id="notdrag" draggable="false" />
                                            </div>

                                            <div className="col-sm-6 iframe-div">
                                                <div className="files browse-bg">
                                                    <Previews preUploadedImages={formData.usedCarImage} handelFilesUpload={this.handelFilesUpload} />
                                                </div>
                                            </div>
                                            <div className="col-sm-3">
                                                <div id="" className="bg-usfulltips">
                                                    <h4><img src={shapeImg} /> {this.props.t('addInventory.usefull_tips')}</h4>
                                                    {
                                                        <ul>
                                                            <li>{this.props.t('addInventory.usefull_tips_1')}</li>
                                                            <li>{this.props.t('addInventory.usefull_tips_2',{stock_type:this.state.stockType})}</li>
                                                            <li>{this.props.t('addInventory.usefull_tips_3')}</li>
                                                        </ul>
                                                    }
                                                        
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-heading">
                                        <h2 className="card-title">{this.props.t('addInventory.car_details',{stock_type:this.state.stockType})}</h2>
                                    </div>
                                    <div className="card-body">
                                        {/* <Fieldset legend="Car Details" /> */}
                                        {savemode=='add' && stock_category==1 ?
                                        <>
                                        <h6>{this.props.t('addInventory.auto_classified')}</h6>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <InputField
                                                    inputProps={{
                                                        id: "classifiedStock",
                                                        type: "checkbox",
                                                        name: "classifiedStock",
                                                        value: classifiedStock,
                                                        label: this.props.t('addInventory.make_classified'),
                                                        checked: this.state.autoClassifiedFlag
                                                    }}
                                                    onChange={this.setClassifiedStockStatus}
                                                />
                                            </div>
                                        </div> 
                                        </>: ''
                                        }
                                        <h6>{this.props.t('addInventory.'+CONF_VAL.CAR_MAKE_YEAR_MONTH_LABEL)}*</h6>
                                        <div className="row">
                                            <div className="col-sm-3 form-group">
                                                <label>{this.props.t('addInventory.select_month')}*</label>
                                                <Select
                                                    isDisabled={monthDisable}
                                                    value={formData.make_month}
                                                    onChange={this.handleChange.bind(this, 'make_month')}
                                                    options={MONTH}
                                                    name="make_month"
                                                    placeholder={this.props.t('addInventory.select_month')}
                                                    value={MONTH.filter(({ value }) => value === formData.make_month)}
                                                    getOptionLabel={({ label }) => label[lang_code]}
                                                    getOptionValue={({ value }) => value}
                                                />
                                                <span className="form-error">{errors.make_month}</span>
                                            </div>
                                            <div className="col-sm-3 form-group" >
                                                <label>{this.props.t('addInventory.select_year')}*</label>
                                                <Select
                                                    isDisabled={yearDisable}
                                                    value={formData.make_year}
                                                    onChange={this.handleChange.bind(this, 'make_year')}
                                                    options={year}
                                                    name="make_year"
                                                    placeholder={this.props.t('addInventory.select_year')}
                                                    value={year.filter(({ value }) => value === formData.make_year)}
                                                    getOptionLabel={({ label }) => label}
                                                    getOptionValue={({ value }) => value}
                                                />
                                                <span className="form-error">{errors.make_year}</span>
                                            </div>
                                        </div>
                                        <h6>{this.props.t('addInventory.select_make_model')}*</h6>
                                        <div className="clearfix">
                                            <MmvDropDown coloumnClass="col-sm-3 form-group" showVersion='1' stock_category_id={stock_category} defaultVersion={formData.version_id} defaultModel={formData.model_id} defaultMake={formData.make_id} handleOptionChange={this.handleVersionChange} makeDisable={makeDisable} modelDisable={modelDisable} multiSelectEnable={false} />
                                            <span className="form-error">{errors.version_id}</span>
                                        </div>
                                       
                                        <div className={this.state.hideshow}>
                                            <div className="col-sm-9 ">
                                        <ul className="pointer-list mrg-b15 alert alert-default">
                                            <li>
                                                <span className="pointer-label">{this.props.t('addInventory.fuel_type')}</span>
                                                <span className="pointer-detail">{(versionDetailJson.fuel_type)?versionDetailJson.fuel_type:'N/A'}</span>
                                            </li>
                                            <li>
                                                <span className="pointer-label">{this.props.t('addInventory.transmission')}</span>
                                                <span className="pointer-detail">{(versionDetailJson.transmission)?versionDetailJson.transmission:'N/A'}</span>
                                            </li>
                                            <li>
                                                <span className="pointer-label">{this.props.t('addInventory.body_type')}</span>
                                                <span className="pointer-detail">{(versionDetailJson.car_body_type)?versionDetailJson.car_body_type:'N/A'}</span>
                                            </li>
                                            <li>
                                                <span className="pointer-label">{this.props.t('addInventory.engine_capacity')}</span>
                                                <span className="pointer-detail">{(versionDetailJson.engine_capacity)?versionDetailJson.engine_capacity:'N/A'}</span>
                                            </li>
                                            {/* <li>
                                                <span className="pointer-label">{this.props.t('addInventory.drive_type')}</span>
                                                <span className="pointer-detail">{versionDetailJson.drive_type}</span>
                                            </li> */}
                                        </ul>
                                        </div>
                                        </div>
                                        <h6>{this.props.t('addInventory.reg_details')}</h6>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <InputField
                                                    inputProps={{
                                                        id: "is_registered_car",
                                                        type: "checkbox",
                                                        name: "is_registered_car",
                                                        value: 1,
                                                        label: this.props.t('addInventory.is_reg_car',{stock_type:this.state.stockType}),
                                                        checked: formData.is_registered_car == 1
                                                    }}
                                                    onChange={this.setRegistrationStatus}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <InputField
                                                    inputProps={{
                                                        id: "reg_no",
                                                        type: "text",
                                                        placeholder: this.props.t('addInventory.reg_no'),
                                                        name: "reg_no",
                                                        autocompleate: "off",
                                                        label: this.props.t('addInventory.reg_no')+(formData.is_registered_car ? '*' : ''),
                                                        value: (formData.reg_no) ? formData.reg_no : '',
                                                        disabled: carRegisterEnableStatus,
                                                        maxLength: 9,
                                                        dataerror: errors.reg_no
                                                    }}
                                                    onChange={this.handleChangetext}
                                                />                                                
                                            </div>
                                            {!DISABLE_REGISTRATION_NUMBER_TYPE ?
                                                <div className="col-sm-3">
                                                    <label>{this.props.t('addInventory.reg_no_type')}</label>
                                                    <div className=" rc-inline" style={{ paddingTop: "10px" }}>
                                                        <InputField
                                                            inputProps={{
                                                                id: "number_type_1",
                                                                type: "radio",
                                                                name: "number_type",
                                                                value: "ODD",
                                                                checked: formData.number_type == 'ODD',
                                                                label: this.props.t('addInventory.odd'),
                                                                disabled: carRegisterEnableStatus
                                                            }}
                                                            onChange={this.setNumberType}
                                                        />
                                                        <InputField
                                                            inputProps={{
                                                                id: "number_type_2",
                                                                type: "radio",
                                                                name: "number_type",
                                                                value: "EVEN",
                                                                checked: formData.number_type == 'EVEN',
                                                                label: this.props.t('addInventory.even'),
                                                                disabled: carRegisterEnableStatus
                                                            }}
                                                            onChange={this.setNumberType}
                                                        />
                                                    </div>
                                                </div> : ''}

                                            <div className="col-sm-3 form-group pad-t30">
                                                <InputField
                                                    inputProps={{
                                                        id: "is_reg_no_show",
                                                        type: "checkbox",
                                                        name: "is_reg_no_show",
                                                        value: 1,
                                                        checked: formData.is_reg_no_show == 1,
                                                        label: this.props.t('addInventory.is_reg_show'),
                                                        disabled: carRegisterEnableStatus
                                                    }}
                                                    onChange={this.setRegistrationShow}
                                                />
                                                <span className="form-error">{errors.is_reg_no_show}</span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3 form-group">
                                                <label>{this.props.t('addInventory.reg_place')}{(formData.is_registered_car ? '*' : '')}</label>
                                                <Select
                                                    value={formData.reg_place_city_id}
                                                    onChange={this.handleChangeById.bind(this, 'reg_place_city_id')}
                                                    options={cityAllList}
                                                    name="reg_place_city_id"
                                                    id="reg_place_city_id"
                                                    value={cityAllList.filter(({ id }) => id === formData.reg_place_city_id)}
                                                    getOptionLabel={({ name }) => name}
                                                    getOptionValue={({ id }) => id}
                                                    isDisabled={carRegisterEnableStatus}
                                                />
                                                <span className="form-error">{errors.reg_place_city_id}</span>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-3 form-group">
                                                <label>{this.props.t('addInventory.reg_month')}</label>
                                                <Select
                                                    value={formData.reg_month}
                                                    onChange={this.handleChange.bind(this, 'reg_month')}
                                                    options={MONTH}
                                                    name="reg_month"
                                                    value={MONTH.filter(({ value }) => value === formData.reg_month)}
                                                    getOptionLabel={({ label }) => label[lang_code]}
                                                    OptionValue={({ value }) => value}
                                                    isDisabled={carRegisterEnableStatus}
                                                />
                                                <span className="form-error">{errors.reg_month}</span>
                                            </div>
                                            <div className="col-sm-3 form-group">
                                                <label>{this.props.t('addInventory.reg_year')}</label>
                                                <Select
                                                    onChange={this.handleChange.bind(this, 'reg_year')}
                                                    options={year}
                                                    name="reg_year"
                                                    value={year.filter(({ value }) => value === formData.reg_year)}
                                                    getOptionLabel={({ label }) => label}
                                                    getOptionValue={({ value }) => value}
                                                    isDisabled={carRegisterEnableStatus}
                                                />
                                                <span className="form-error">{errors.reg_year}</span>
                                            </div>
                                        </div>

                                        <div className="row">

                                            {/* <div className="col-sm-3 form-group">
                                                <label htmlFor="dealership_email" className="">{this.props.t('addInventory.reg_valid_month_year')}</label>

                                                <DatePicker showYearDropdown showMonthDropdown disabled={carRegisterEnableStatus} className="form-control" selected={(formData.reg_valid_date) ? new Date(formData.reg_valid_date) : ''} name="reg_valid_date" id="reg_valid_date" placeholder="" label={this.props.t('addInventory.reg_valid_date')} autoComplete="off" onChange={this.handledate.bind(this, 'reg_valid_date')} />

                                                <span className="form-error">{errors.reg_valid_date}</span>
                                            </div> */}

                                            <div className="col-sm-3 form-group">
                                                <label>{this.props.t('addInventory.number_plate_valid_month')}</label>
                                                <Select
                                                    value={formData.reg_valid_month}
                                                    onChange={this.handleChange.bind(this, 'reg_valid_month')}
                                                    options={MONTH}
                                                    name="reg_valid_month"
                                                    value={MONTH.filter(({ value }) => value === formData.reg_valid_month)}
                                                    getOptionLabel={({ label }) => label[lang_code]}
                                                    OptionValue={({ value }) => value}
                                                    isDisabled={carRegisterEnableStatus}
                                                />
                                                <span className="form-error">{errors.reg_valid_month}</span>
                                            </div>
                                            <div className="col-sm-3 form-group">
                                                <label>{this.props.t('addInventory.number_plate_valid_year')}</label>
                                                <Select
                                                    onChange={this.handleChange.bind(this, 'reg_valid_year')}
                                                    options={validreg_year}
                                                    name="reg_valid_year"
                                                    value={validreg_year.filter(({ value }) => value === formData.reg_valid_year)}
                                                    getOptionLabel={({ label }) => label}
                                                    getOptionValue={({ value }) => value}
                                                    isDisabled={carRegisterEnableStatus}
                                                />
                                                <span className="form-error">{errors.reg_valid_year}</span>
                                            </div>
                                        </div>

                                        <h6>{this.props.t('addInventory.other_detail')}*</h6>
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <InputField
                                                    inputProps={{
                                                        id: "km_driven",
                                                        type: "text",
                                                        maxLength: 8,
                                                        placeholder: this.props.t('addInventory.km'),
                                                        name: "km_driven",
                                                        autocompleate: "off",
                                                        label: this.props.t('addInventory.km_driven')+'*',
                                                        value: (formData.display_km) ? formData.display_km : '',
                                                        dataerror: errors.km_driven
                                                    }}
                                                    onChange={this.handleChangetext}
                                                />
                                                <span className="num-words">{this.__numToWords(this.state.formData.km_driven)}</span>                                                
                                            </div>
                                            <div className="col-sm-3 form-group">
                                                <label>{this.props.t('addInventory.color')}*</label>
                                                <Select
                                                    value={formData.uc_colour_id}
                                                    onChange={this.handleChangeById.bind(this, 'uc_colour_id')}
                                                    options={colorList}
                                                    name="uc_colour_id"
                                                    value={colorList.filter(({ id }) => id === formData.uc_colour_id)}
                                                    getOptionLabel={({ color }) => color}
                                                    getOptionValue={({ id }) => id}
                                                />
                                                {
                                                    (formData.uc_colour_id == 0) ? <InputField
                                                        inputProps={{
                                                            id: "other_colour",
                                                            type: "text",
                                                            placeholder: this.props.t('addInventory.other_colour_placeholder'),
                                                            name: "other_colour",
                                                            autocompleate: "off",
                                                            label: this.props.t('addInventory.other_colour_label'),
                                                            value: (formData.other_colour) ? formData.other_colour : '',
                                                            maxLength: 50
                                                        }}
                                                        onChange={this.handleChangetext}
                                                    /> : ''
                                                }
                                                <span className="form-error">{errors.uc_colour_id}</span>
                                            </div>
                                            <div className="col-sm-3 form-group">
                                                <label>{this.props.t('addInventory.no_owner')}*</label>
                                                <Select
                                                    value={formData.owner_type}
                                                    onChange={this.handleChange.bind(this, 'owner_type')}
                                                    options={OWNER}
                                                    name="owner_type"
                                                    value={OWNER.filter(({ value }) => value === formData.owner_type)}
                                                    getOptionLabel={({ label }) => label[lang_code]}
                                                    getOptionValue={({ value }) => value}
                                                />
                                                <span className="form-error">{errors.owner_type}</span>
                                            </div>
                                        </div>                                        
                                        <h6>{this.props.t('addInventory.insurance')}*</h6>
                                        <div className="row">
                                            <div className="col-sm-12 rc-inline">
                                                <InputField
                                                    inputProps={{
                                                        id: "Insurance-1",
                                                        type: "radio",
                                                        name: "insurance_type",
                                                        value: 1,
                                                        label: this.props.t('addInventory.no_insurance'),
                                                        checked: formData.insurance_type == 1,
                                                    }}
                                                    onChange={this.setInsuranceType}
                                                />

                                                <InputField
                                                    inputProps={{
                                                        id: "Insurance-2",
                                                        type: "radio",
                                                        name: "insurance_type",
                                                        value: 2,
                                                        label: this.props.t('addInventory.tlo'),
                                                        checked: formData.insurance_type == 2
                                                    }}
                                                    onChange={this.setInsuranceType}
                                                />

                                                <InputField
                                                    inputProps={{
                                                        id: "Insurance-3",
                                                        type: "radio",
                                                        name: "insurance_type",
                                                        value: 3,
                                                        label: this.props.t('addInventory.all_risk'),
                                                        checked: formData.insurance_type == 3
                                                    }}
                                                    onChange={this.setInsuranceType}
                                                />

                                                <InputField
                                                    inputProps={{
                                                        id: "Insurance-4",
                                                        type: "radio",
                                                        name: "insurance_type",
                                                        value: 4,
                                                        label: this.props.t('addInventory.combined'),
                                                        checked: formData.insurance_type == 4
                                                    }}
                                                    onChange={this.setInsuranceType}
                                                />
                                                <span className="form-error">{errors.insurance_type}</span>
                                            </div>
                                        </div>

                                        <h6>{this.props.t('addInventory.tax_expiry')}</h6>                                                    
                                        <div className="row">
                                            <div className="col-sm-3 form-group">
                                                <label>{this.props.t('addInventory.tax_expiry_month')}</label>
                                                <Select
                                                    value={formData.tax_expiry_month}
                                                    onChange={this.handleChange.bind(this, 'tax_expiry_month')}
                                                    options={MONTH}
                                                    name="tax_expiry_month"
                                                    value={MONTH.filter(({ value }) => value === formData.tax_expiry_month)}
                                                    getOptionLabel={({ label }) => label[lang_code]}
                                                    OptionValue={({ value }) => value}
                                                />
                                                <span className="form-error">{errors.tax_expiry_month}</span>
                                            </div>
                                            <div className="col-sm-3 form-group">
                                                <label>{this.props.t('addInventory.tax_expiry_year')}</label>
                                                <Select
                                                    onChange={this.handleChange.bind(this, 'tax_expiry_year')}
                                                    options={tax_expiry_year}
                                                    name="tax_expiry_year"
                                                    value={tax_expiry_year.filter(({ value }) => value === formData.tax_expiry_year)}
                                                    getOptionLabel={({ label }) => label}
                                                    getOptionValue={({ value }) => value}
                                                />
                                                <span className="form-error">{errors.tax_expiry_year}</span>
                                            </div>
                                        </div>

                                        <h6>{this.props.t('addInventory.desc')}</h6>
                                        <div className="row">
                                            <div className="col-sm-6 form-group ">
                                                {/* <textarea name="description" placeholder={this.props.t('addInventory.additional_details_car')} className="form-control en-textarea" onChange={this.handleChangetext} value={formData.description ? formData.description: ''}></textarea> */}
                                                
                                                <Editor                                                            
                                                    key={formData.stock_used_car_id || 1}
                                                    apiKey="9gh0yn6tndfh684vhu2ibdccw57q3n2pks1mxrmqyvqzmhaa"
                                                    initialValue={formData.description ? formData.description: ''}
                                                    init={{
                                                    height: 200,
                                                    menubar: false,
                                                    branding: false,                                                    
                                                    placeholder: this.props.t('addInventory.additional_details_car',{stock_type:this.state.stockType}),
                                                    init_instance_callback:  (editor) => {
                                                        this.setDescriptionEditorInstanse(editor);
                                                        document.querySelector('button.tox-statusbar__wordcount').click();                                                        
                                                        document.querySelector('.tox .tox-editor-header').style.zIndex = '0';
                                                    },
                                                    plugins: [
                                                        'advlist autolink lists link charmap print preview anchor',
                                                        'searchreplace visualblocks code fullscreen',
                                                        'insertdatetime table paste code help wordcount'
                                                    ],
                                                    toolbar:
                                                        'undo redo | formatselect | bold italic underline | \
                                                        alignleft aligncenter alignright alignjustify | \
                                                        bullist numlist outdent indent | removeformat'
                                                    }}
                                                    onEditorChange={this.handleEditorChange}
                                                />
                                                <span className="form-error">{errors.description}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-heading">
                                        <h2 className="card-title">{this.props.t('addInventory.price_detail')}</h2>
                                    </div>
                                    <div className="card-body">
                                        {/* <Fieldset legend="Price Details" /> */}
                                        <div className="row">
                                            <div className="col-sm-3">
                                                <InputField
                                                    inputProps={{
                                                        id: "car_price",
                                                        placeholder: this.props.t('addInventory.price'),
                                                        name: "car_price",
                                                        autocompleate: "off",
                                                        type: "text",
                                                        maxLength: 15,
                                                        label: this.props.t('addInventory.price')+'*',
                                                        value: formData.display_price ? formData.display_price : '',
                                                        dataerror: errors.car_price
                                                    }}
                                                    onChange={this.handleChangetext}
                                                />
                                                <span className="num-words">{this.__numToWords(this.state.formData.car_price)}</span>                                                
                                            </div>
                                        </div>
                                    </div>



                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-sm-3">
                                                        <InputField
                                                            inputProps={{
                                                                id: "downpayment",
                                                                placeholder: this.props.t('addInventory.down_payment'),
                                                                name: "downpayment",
                                                                autocompleate: "off",
                                                                type: "text",
                                                                maxLength: 15,
                                                                label: this.props.t('addInventory.down_payment'),
                                                                value: formData.display_downpayment ? formData.display_downpayment : '',
                                                                dataerror: errors.display_downpayment
                                                            }}
                                                            onChange={this.handleChangetext}
                                                        />
                                            </div>
                                            <div className="col-sm-3">
                                                        <InputField
                                                            inputProps={{
                                                                id: "emi",
                                                                placeholder: this.props.t('addInventory.emi'),
                                                                name: "emi",
                                                                autocompleate: "off",
                                                                type: "text",
                                                                maxLength: 15,
                                                                label: this.props.t('addInventory.emi'),
                                                                value: formData.display_emi ? formData.display_emi : '',
                                                                dataerror: errors.display_emi
                                                            }}
                                                            onChange={this.handleChangetext}
                                                        />
                                            </div>
                                            <div className="col-sm-3">
                                                <div className="form-field">
                                                    <div>
                                                        <label>{this.props.t('addInventory.emi_count')}</label>
                                                        <Select
                                                            value={formData.emi_count}
                                                            onChange={this.handleChange.bind(this, 'emi_count')}
                                                            options={tenureList}
                                                            name="emi_count"
                                                            value={tenureList.filter(({ value }) => value === formData.emi_count)}
                                                            getOptionLabel={({ value }) => value}
                                                            OptionValue={({ id }) => id}
                                                        />
                                                        <span className="form-error">{errors.emi_count}</span>
                                                    </div>
                                                </div>
                                            </div>
                                    </div>
                                </div>

                                </div>
                                <div className="row mrg-b30">
                                    <div className="col-sm-3 form-group">
                                        <button type="submit" id="savedetail" name="submit" className="btn btn-primary btn-lg" onClick={savemode == 'add' ? this.submitAddStockForm : this.submitUpdateStockForm}>{this.props.t('addInventory.save_detail')}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
        dealer_city_id: (state.dealerData.city_ids) ? state.dealerData.city_ids[0] : 0
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('inventory')(connect(mapStateToProps, mapDispatchToProps)(AddStock));
