import React, { Component } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { Redirect , NavLink} from 'react-router-dom';
import Select from 'react-select';
import InputField from './../../elements/Input';
import CheckBox from './../../elements/CheckBox';
import Button from './../../elements/Button';
import Accordion from './../../elements/Accordion';
import MasterContext from './MasterContext';
import { DealerService } from '../../../service';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {MOBILE_VALIDATION} from './../../../config/constant';
class EmployeeDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            key: 1,
            role: "",
            loading:true,
            basic_details:props.basic_details || {},
            employees: [{id:0, name:"Other"}],
            editData: [],
            groups: [{
                code: "_buyer_lead",
                title: "Buyer Leads",
                enquiry_type_id: 1,
                error:{},
            }, {
                code: "_seller_lead",
                title: "Seller Leads",
                enquiry_type_id: 2,
                error:{},
            }, {
                code: "_finance_enq",
                title: "Finance Enquiries",
                enquiry_type_id: 3,
                error:{},
            }]
        };
    }
    handelError = (err) => {
        this.setState({loading:false})
       // console.error("_______________handelError() > ", err);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ 
            basic_details: nextProps.basic_details,
         });
    }
    get get_dealer_id_hash() {
        return this.props.dealer_id_hash
    }
    defContact = (data) => {
        return  {
            "enquiry_type_id": data.enquiry_type_id, //1
            "receive_by": data.receive_by, //'SMS', 'EMAIL'
            "user_id": data.user_id,
            "contact_detail": data.contact_detail,
            "status": data.status || 0,
            "EnquiryType.type_short_code": data.type_short_code, // "_buyer_lead"
        }
    }
    componentDidMount = () => {

        DealerService.getEmployees(this.get_dealer_id_hash).then((result) => {
            //console.log("DealerService.getEmployees() AFTER > result ", result);
            let other = {id:0, name:"Other"};
            if (result.status == 200 && result.data && result.data.status == 200) {
                let employees = result.data.data ? result.data.data : [];
                employees.push(other);
                this.setState({ employees: employees });
            }
            return this.fetchEditData();
        }).then(response => {
        }).catch(this.handelError);
    }
    fetchEditData = () => {
        return new Promise((resolve, reject) => {
            DealerService.getSettingContacts(this.get_dealer_id_hash).then(response => {
               // console.log("fetchEditData() > DealerService.getSettingContacts() AFTER > response ", response);
                if (response.status == 200 && response.data && response.data.status == 200) {
                    this.setState({ editData: response.data.data || [] ,loading:false});
                    resolve();
                }
                else {
                    reject(response.data)
                }
            }).catch(reject);
        })
    }

    setError = () => {

    }
    isValidEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);      
    }
    handleChangeCheckAll = (group) => (e) => {
        let is_active = e.target.checked ? '1' : '0';
        this.setState({ editData: this.state.editData.map(v => {
            if(v['EnquiryType.type_short_code'] == group.code) v.status = is_active;
            return v;
        }) })
    }
    isValidForm = (list, enquiry_type_id, type_short_code) => {
        list = list || [];
        let groupRow = this.state.groups.filter(v => v.code == type_short_code)[0]
        if (groupRow) {
            groupRow.error = {}
            list.map(v => {
                if (v.status == null) v.status = '0';
                if (v.status == 1) {
                    if (v.receive_by == "EMAIL" && v.user_id == null) {
                        groupRow.error.emailUser = this.props.t("dealerDetails.Contact_Settings.select_required");
                        
                    }
                    if (v.receive_by == "EMAIL" && !this.isValidEmail(v.contact_detail)) {
                        groupRow.error.emailText = this.props.t("dealerDetails.Contact_Settings.valid_email");
                    }
                    if (v.receive_by == "SMS" && v.user_id == null) {
                        groupRow.error.smsUser = this.props.t("dealerDetails.Contact_Settings.select_required");
                    }
                    if (v.receive_by == "SMS" && !v.contact_detail) {
                        groupRow.error.smsText = this.props.t("dealerDetails.Contact_Settings.valid_mobile");
                    }else if(v.contact_detail && v.receive_by == "SMS"){
                        if (!MOBILE_VALIDATION.test(v.contact_detail))  groupRow.error.smsText = "Please Enter Valid Phone Number"
                    }

                    if (v.receive_by == "WHATSAPP" && v.user_id == null) {
                        groupRow.error.whatsUser = this.props.t("dealerDetails.Contact_Settings.select_required");
                    }

                    if (v.receive_by == "WHATSAPP" && !v.contact_detail) {
                        groupRow.error.whatsText = this.props.t("dealerDetails.Contact_Settings.valid_mobile");
                    }else if(v.contact_detail && v.receive_by == "WHATSAPP"){
                        if (!MOBILE_VALIDATION.test(v.contact_detail))  groupRow.error.whatsText = "Please Enter Valid Phone Number"
                    }
                }
                else if (v.status == 0) {
                    if (!v.user_id) v.user_id = 0;
                }
            })
            this.setState({ groups: this.state.groups.map(v => v.code == groupRow.code ? groupRow : v) })
            return Object.keys(groupRow.error).length ? false : true
        }
        return true
    }
    onSubmit = (enquiry_type_id, type_short_code) => (e) => {
        e.preventDefault()
        let dataSave = this.state.editData.filter(v => {
            return (type_short_code == v['EnquiryType.type_short_code'] && enquiry_type_id == v.enquiry_type_id)
        })
        let is_valid = this.isValidForm(dataSave, enquiry_type_id, type_short_code);
        if(is_valid) {
            this.setState({loading:true})
            DealerService.contactSettingSave(this.get_dealer_id_hash, dataSave).then(response => {
                if (response.status == 200 && response.data && response.data.status == 200) {
                    this.setState({loading:false})
                    this.fetchEditData().then().catch(this.err);
                    toast.success(response.data.message || "Saved Successfully")
                }
                else {
                    this.handelError(response.data)
                }
            }).catch(this.handelError);
        }
        else {
            this.handelError({is_valid});
        }
    }
    handleChange = (group, field, enquiry_type_id, type_short_code) => (e) => {
        let idx = this.state.editData.findIndex(v => {
            return (type_short_code == v['EnquiryType.type_short_code'] && enquiry_type_id == v.enquiry_type_id && v.receive_by.toLowerCase()==group)
        })
        let row = this.state.editData[idx] || this.defContact({ enquiry_type_id, receive_by:group.toUpperCase(), type_short_code });
        if (row) {
            if (field == 'user') {
                row.user_id = e.id
                if(group == "sms")
                    row.contact_detail = e.mobile || "";
                else if(group == "email")
                    row.contact_detail = e.email || "";
                else if(group == "whatsapp")
                    row.contact_detail = e.mobile || "";
            }
            else if (field == 'status') {
                row.status = e.target.checked ? '1' : '0';
            }
            else if (field == 'contact_detail') {
                row.contact_detail = e.target.value;
            }
        }
        let editData = this.state.editData;
        if (idx == -1) {
            editData.push(row);
        }
        else {
            editData = editData.map((v, k) => k === idx ? row : v);
        }
        this.setState({ editData: editData })
    }
    get_value = (group, field, enquiry_type_id, type_short_code) => {
        let row = this.state.editData.filter(v => {
            return (type_short_code == v['EnquiryType.type_short_code'] && enquiry_type_id == v.enquiry_type_id && v.receive_by.toLowerCase()==group)
        })[0]
        if(!row) {
            row = this.defContact({ enquiry_type_id, receive_by:group.toUpperCase(), type_short_code })
            this.state.editData.push(row)
        }
        if (row) {
            if(field == 'status'){
                return row && row['status'] == '1' ? true : false;
            }
            else if(field == 'user'){
                let a =  row && row['user_id'];
                return a;
            }
            else if(field == 'contact_detail'){
                return row && row['contact_detail'];
            }
        }
    }

    render() {
        let basicinfo = this.state.basic_details;
        return (
            <MasterContext.Consumer>{masters => (
            <div> {/* masters - {JSON.stringify(masters)} */}
            <h1>{this.props.t('dealerDetails.Contact_Settings.Contact_Setting')} <span className="f12">({basicinfo.organization} - {basicinfo.gcd_code})</span></h1>
            <div className="card os-animation animated fadeInDown" data-os-animation="fadeInDown" data-os-animation-delay="0s">
            <div className="card-heading"><h2 className="card-title">{this.props.t('dealerDetails.Contact_Settings.Contact_Setting')}</h2></div>
                <div className={(this.state.loading) ? "card-body loading" : "card-body"}>
                    {this.state.groups.map((v, k)=>
                        <Accordion title={v.title} key={v.code} active={k == 0 ? 'true' : ''}>
                            <form onSubmit={this.onSubmit(v['enquiry_type_id'], v['code'])} key={'form_'+v['code']}>
                            <div className="form-group rc-inline">
                                <CheckBox type="checkbox" name="checkall" id={`checkall_${v['code']}`} value="checkall" label={this.props.t('dealerDetails.Contact_Settings.Check_all')}
                                    onChange={this.handleChangeCheckAll(v)}/>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25 rc-inline">
                                    <CheckBox type="checkbox" label={this.props.t('dealerDetails.Contact_Settings.SMS')} name={'sms_status_'+v['enquiry_type_id']}
                                        id={'sms_status_'+v['enquiry_type_id']} key={'sms_status_'+v['enquiry_type_id']}
                                        onChange={this.handleChange("sms","status",v['enquiry_type_id'], v['code'])}
                                        checked={this.get_value("sms","status",v['enquiry_type_id'], v['code'])}
                                        />
                                </div>
                                <div className="col-sm-3 form-group">
                                <label>{this.props.t('dealerDetails.Contact_Settings.Employee')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                    <Select
                                        id={`user_sms_${v['enquiry_type_id']}`}
                                        isDisabled={!this.get_value("sms","status",v['enquiry_type_id'], v['code'])}
                                        onChange={this.handleChange("sms","user",v['enquiry_type_id'], v['code'])}
                                        options={this.state.employees}
                                        name={`user_sms_${v['enquiry_type_id']}`}
                                        placeholder="Select Employee"
                                        value={this.state.employees.filter(({ id }) => id == this.get_value("sms","user",v['enquiry_type_id'], v['code']))}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ id }) => id}
                                        required={true}
                                    />
                                    {v.error.smsUser && <div className="error show">{v.error.smsUser}</div>}
                                </div>
                                <div className="col-sm-3">
                                    <InputField type="number" name={`user_number_${v['enquiry_type_id']}`}
                                        id={`sms_user_number_${v['enquiry_type_id']}`} placeholder={this.props.t('dealerDetails.Contact_Settings.Enter_Dealership_Mobile')}
                                        disabled={!this.get_value("sms","status",v['enquiry_type_id'], v['code']) ||
                                            this.get_value("sms","user",v['enquiry_type_id'], v['code']) }
                                        label={this.props.t('dealerDetails.Contact_Settings.Dealership_Contact_No')}
                                        required={true}
                                        error={v.error.smsText}
                                        value={this.get_value("sms","contact_detail",v['enquiry_type_id'], v['code'])} 
                                        onChange={this.handleChange("sms","contact_detail",v['enquiry_type_id'], v['code'])}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25 rc-inline">
                                    <CheckBox type="checkbox" label={this.props.t('dealerDetails.Contact_Settings.Email')} name={'email_status_'+v['enquiry_type_id']}
                                        id={'email_status_'+v['enquiry_type_id']} key={'email_status_'+v['enquiry_type_id']}
                                        onChange={this.handleChange("email","status",v['enquiry_type_id'], v['code'])}
                                        checked={this.get_value("email","status",v['enquiry_type_id'], v['code'])}
                                        />
                                </div>
                                <div className="col-sm-3 form-group">
                                <label>{this.props.t('dealerDetails.Contact_Settings.Employee')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                    <Select
                                        id={`email_user_id_${v['enquiry_type_id']}`}
                                        isDisabled={!this.get_value("email","status",v['enquiry_type_id'], v['code'])}
                                        onChange={this.handleChange("email","user",v['enquiry_type_id'], v['code'])}
                                        options={this.state.employees}
                                        name={`email_user_id_${v['enquiry_type_id']}`}
                                        placeholder="Select Employee"
                                        value={this.state.employees.filter(({ id }) => id == this.get_value("email","user",v['enquiry_type_id'], v['code']))}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ id }) => id}
                                        required={true}
                                    />
                                    {v.error.emailUser && <div className="error show">{v.error.emailUser}</div>}
                                </div>
                                <div className="col-sm-3">
                                    <InputField type="email" name={`user_email_value_${v['enquiry_type_id']}`} id={`user_email_value_${v['enquiry_type_id']}`} 
                                        placeholder={this.props.t('dealerDetails.Contact_Settings.Enter_Dealership_Email')}
                                        disabled={!this.get_value("email","status",v['enquiry_type_id'], v['code']) ||
                                            this.get_value("email","user",v['enquiry_type_id'], v['code']) }
                                        label={this.props.t('dealerDetails.Contact_Settings.Email')} 
                                        required={true}
                                        error={v.error.emailText}
                                        value={this.get_value("email","contact_detail",v['enquiry_type_id'], v['code'])} 
                                        onChange={this.handleChange("email","contact_detail",v['enquiry_type_id'], v['code'])}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-2 form-group pad-t25 rc-inline">
                                    <CheckBox type="checkbox" label={this.props.t('dealerDetails.Contact_Settings.whatsapp')} name={'whatsapp_status_'+v['enquiry_type_id']}
                                        id={'whatsapp_status_'+v['enquiry_type_id']} key={'whatsapp_status_'+v['enquiry_type_id']}
                                        onChange={this.handleChange("whatsapp","status",v['enquiry_type_id'], v['code'])}
                                        checked={this.get_value("whatsapp","status",v['enquiry_type_id'], v['code'])}
                                        />
                                </div>
                                <div className="col-sm-3 form-group">
                                <label>{this.props.t('dealerDetails.Contact_Settings.Employee')}<span className="required" style={{ color: 'red' }}>*</span></label>
                                    <Select
                                        id={`user_whatsapp_${v['enquiry_type_id']}`}
                                        isDisabled={!this.get_value("whatsapp","status",v['enquiry_type_id'], v['code'])}
                                        onChange={this.handleChange("whatsapp","user",v['enquiry_type_id'], v['code'])}
                                        options={this.state.employees}
                                        name={`user_whatsapp_${v['enquiry_type_id']}`}
                                        placeholder="Select Employee"
                                        value={this.state.employees.filter(({ id }) => id == this.get_value("whatsapp","user",v['enquiry_type_id'], v['code']))}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ id }) => id}
                                        required={true}
                                    />
                                    {v.error.whatsUser && <div className="error show">{v.error.whatsUser}</div>}
                                </div>
                                <div className="col-sm-3">
                                    <InputField type="number" name={`user_number_${v['enquiry_type_id']}`}
                                        id={`whatsapp_user_number_${v['enquiry_type_id']}`} placeholder={this.props.t('dealerDetails.Contact_Settings.Enter_Dealership_Mobile')}
                                        disabled={!this.get_value("whatsapp","status",v['enquiry_type_id'], v['code']) ||
                                            this.get_value("whatsapp","user",v['enquiry_type_id'], v['code']) }
                                        label={this.props.t('dealerDetails.Contact_Settings.Dealership_Whatsapp_No')}
                                        required={true}
                                        error={v.error.whatsText}
                                        value={this.get_value("whatsapp","contact_detail",v['enquiry_type_id'], v['code'])} 
                                        onChange={this.handleChange("whatsapp","contact_detail",v['enquiry_type_id'], v['code'])}/>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-sm-3 col-sm-offset-2"><Button type="submit" disabled={v.disabled==true} btnClass="btn btn-primary"> {this.props.t('dealerDetails.Contact_Settings.Save')} </Button></div>
                            </div>
                            </form>
                        </Accordion>
                    )}
                </div>
                
                <div className="card-footer text-center">
                        {/* <Button btnClass="btn btn-default mrg-r30" ><i className="ic-arrow_back"></i> {this.props.t('dealerDetails.Outlet_Details_master.Previous')}</Button> */}
                        <NavLink to={this.props.prevpage} className="btn btn-default mrg-r30">{this.props.t('dealerDetails.Outlet_Details_master.Previous')} <i className="ic-arrow_back"></i></NavLink>
                        {/* <Button btnClass="btn btn-primary"> {this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></Button> */}
                        <NavLink to={this.props.nextpage} className="btn btn-primary">{this.props.t('dealerDetails.Outlet_Details_master.Next')} <i className="ic-arrow_forward"></i></NavLink>
                    </div>
                </div>
            </div>
            )}</MasterContext.Consumer>
        )
    }
}
const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}
export default withTranslation('dealers')(connect(mapStateToProps, mapDispatchToProps)(EmployeeDetails));